/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useRef ,useState} from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link, useParams , useNavigate } from 'react-router-dom';
import {auth , db} from "../../../dicom/db/firebase";
import {createUserWithEmailAndPassword} from "firebase/auth";

import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
} from "firebase/firestore";


const Admin_Dashboard1 = () => {
  const history = useNavigate();
  const {Center} = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const usersCollectionRef = collection(db, "users");

  const createUser = async () => {
    await addDoc(usersCollectionRef, { email: email , name: name,  roles: "User", userId: auth?.currentUser?.uid });
  };

  const handleSignUp = (e) =>{
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password, name).then((userCredential) =>{
      console.log(userCredential);
      addDoc(usersCollectionRef, { email: email , name: name,  roles: "User", userId: auth?.currentUser?.uid });
      
    }).catch((error) => {
      console.log(error);
    })

    const handle = async () =>{
       const doc_refs = await getDocs(query(usersCollectionRef, where("email", "==", email)))
          
          const res = []
          doc_refs.forEach(country => {
            res.push({
              id: country.id, 
              ...country.data()
            })
          })
          console.log(doc_refs);
          console.log(res , res[0].role);
          if(res[0].roles == "User"){
            history({
              pathname: '/Adminhome/' + res[0].id
            })
          }
        }
        handle();
  }

  let inputRef = useRef();
  let inputRef2 = useRef();


  return (
    <div>
      <Header state={Center}/>
      <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-doctor" state={Center} />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                     <Link to="doctors.html">Doctors </Link>
                    </li>
                    <li className="breadcrumb-item active">Add Doctor</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSignUp}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Doctor Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-12">
                          <div className="form-group local-forms">
                            <label>
                              First Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="name"
                              className="form-control" 
                              type="text"
                              value={name} 
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Email <span className="login-danger">*</span>
                            </label>
                            <input
                              name="email" 
                              className="form-control" 
                              type="email" 
                              value={email} 
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Password <span className="login-danger">*</span>
                            </label>
                            <input
                              ref={inputRef}
                              className="form-control pass-input"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)} 
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Confirm Password{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                               ref={inputRef2}
                               className="form-control pass-confirm"
                               type="password"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              onClick={createUser}
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    </div>
  );
};

export default Admin_Dashboard1;
