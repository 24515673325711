/* eslint-disable no-unused-vars */
import React, { useEffect, useState} from "react";
import { Link ,useParams } from "react-router-dom";
// import "../../src/assets/js/app";
// import { baricon1, imguser, logo, noteicon, noteicon1, searchnormal, settingicon01, user06 } from './imagepath';
import {
  logo,
  baricon,
  baricon1,
  searchnormal,
  imguser,
  noteicon,
  user06,
  settingicon01,
  noteicon1,
} from "./imagepath";

import { auth ,db } from "../dicom/db/firebase";
import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";

const Header = () => {
 
  const {Admin} = useParams();

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
    document.getElementsByTagName("html")[0].classList.toggle('menu-opened');
    document.getElementsByClassName("sidebar-overlay")[0].classList.toggle("opened");
  };

  const openDrawer = () => {
    const div = document.querySelector(".main-wrapper");
    if (div?.className?.includes("open-msg-box")) {
      div?.classList?.remove("open-msg-box");
    } else {
      div?.classList?.add("open-msg-box");
    }
  };
  const [name, setname] = useState('');
   const [role, setrole] = useState('');
  const locationCollectionRef = collection(db, "users");

    const getState = async () => {
       try {
            const doc_refs1 = await getDocs(query(locationCollectionRef))          
            const res2 = [];
            const res3 = [];
            doc_refs1.forEach(country => {
              res2.push({
                id: country.id, 
                ...country.data()
              })
            })
            for (let i = 0; i < res2.length; i++) {
              if(res2[i].id == Admin){
                console.log(res2[i].name)
                setname(res2[i].name)
                setrole(res2[i].roles)
              }
            }
            
            
          } 
      catch (err) {
          console.error(err);
          }
    };

const notificationsCollectionRef = collection(db, "notifications");
    const [notification, setnotification] = useState([]);
    const getnotifications = async () => {
       try {
            const doc_refs1 = await getDocs(query(notificationsCollectionRef))          
            const res154 = [];
            doc_refs1.forEach(country => {
              res154.push({
                id: country.id, 
                ...country.data()
              })
            })
            const datares = [];
            for (let i = 0; i < res154.length; i++) {
              if(res154[i].user_id == Admin){
                datares.push({
                  id: res154[i].id, 
                  Notification: res154[i].Notification,
                })
                console.log(res154[i].Notification)
                
              }
            }setnotification(datares)
          } 
      catch (err) {
          console.error(err);
          }
    };


  useEffect(() => {
    getnotifications();
    getState();
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    // maximizeBtn.addEventListener('click', handleClick);

    return () => {
      // maximizeBtn.removeEventListener('click', handleClick);
    };
  }, []);
  return (
    <div className="main-wrapper">
      <div className="header">
        <div className="header-left">
          <Link to={"/Adminhome/" + Admin} className="logo">
            <img src={logo} width={35} height={35} alt="" />{" "}
            <span>Pre Clinic</span>
          </Link>
        </div>
        <Link id="toggle_btn" to="#" onClick={handlesidebar}>
          <img src={baricon} alt="" />
        </Link>
        <Link id="mobile_btn" className="mobile_btn float-start" to="#" onClick={handlesidebarmobilemenu}>
          <img src={baricon1} alt="" />
        </Link>
        <div className="top-nav-search mob-view">
          <form>
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
            />
            <Link className="btn">
              <img src={searchnormal} alt="" />
            </Link>
          </form>
        </div>
        <ul className="nav user-menu float-end">
          <li className="nav-item dropdown d-none d-sm-block">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <img src={noteicon} alt="" />
              <span className="pulse" />{" "}
            </Link>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span>Notifications</span>
              </div>
              <div className="drop-scroll">
                <ul className="notification-list">
                  {notification?.map((v) => {
                    return (
                      <li className="notification-message" key={v.id}>
                        <Link to="/user-activity">
                          <div className="media">
                            <div className="media-body">
                              <p className="noti-details">
                                <span className="noti-title">
                                  {v.Notification}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown has-arrow user-profile-list">
            <Link
              to={"/profile/"+ Admin}
              className="nav-link user-link"
            >
              <div className="user-names">
                <h5>{name}</h5>
                <span>{role}</span>
              </div>
              <div className="user-img">
                <img src={auth?.currentUser?.photoURL} alt="Admin" />
              </div>
            </Link>
          </li>
        </ul>
        <div className="dropdown mobile-user-menu float-end">
          <Link
            to="#"
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-ellipsis-vertical" />
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
             <Link className="dropdown-item" to={"/profile/"+ Admin}>
                My Profile
              </Link>
              <Link className="dropdown-item" to={"/edit-profile/"+ Admin}>
                Edit Profile
              </Link>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Header;
