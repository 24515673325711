/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { blog, dashboard, doctor, doctorschedule, logout, menuicon04, menuicon06, menuicon08, menuicon09, menuicon10, menuicon11, menuicon12, menuicon14, menuicon15, menuicon16, patients, sidemenu } from './imagepath';
import { Scrollbars } from 'react-custom-scrollbars';

import { signOut } from "firebase/auth";
import {auth} from "../dicom/db/firebase";
import { useNavigate } from "react-router-dom";


const Sidebar = (props) => {

  const [sidebar, setSidebar] = useState("");
  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    const ulDiv = document.querySelector(`.${item1}`);
    e?.target?.className ? ulDiv.style.display = 'none' : ulDiv.style.display = 'block'
    e?.target?.className ? div.classList.remove('subdrop') : div.classList.add('subdrop');
  }
    const history = useNavigate()

    const handlelogout = () =>{
        signOut(auth).then(val=>{
            console.log(val,"val")
            history('/login')
        })
    }
  useEffect(() => {
    if (props?.id && props?.id1) {
      const ele = document.getElementById(`${props?.id}`);
      handleClick(ele, props?.id, props?.id1);
    }
  }, [])


  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}
            >
              <ul>
                <li className="menu-title">Main</li>
                 <li>
                  <Link className='Adminhome'  to={"/Adminhome/" + props?.state}>
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="submenu" style={{ display: "none" }} >
                  <Link  to="#" id="menu-item" onClick={(e) => {

                    handleClick(e, "menu-item", "menu-items")
                  }}>
                    <span className="menu-side menu-items">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span> Dashboard </span>
                  </Link>
                  {/* <ul className='menu-items'>
                    <li>
                      <Link  to="#">Admin Dashboard</Link>
                    </li>
                  
                  </ul> */}
                </li>
                <li className="submenu">
                  <Link  to={"/doctorlist/" + props?.state} id="menu-item1" onClick={(e) => {
                    // setSidebar('Doctors')
                    handleClick(e, "menu-item1", "menu-items1")
                  }}>
                    <span className="menu-side">
                      <img src={doctor} alt="" />
                    </span>{" "}
                    <span> Centers </span>
                  </Link>
                  <ul style={{ display: sidebar === 'Doctors' ? 'block' : 'none' }} className="menu-items1"></ul>
                </li>
                <li className="submenu">
                  <Link to={"/patientslist/" + props?.state} id="menu-item2" onClick={(e) => handleClick(e, "menu-item2", "menu-items2")}>
                    <span className="menu-side">
                      <img src={patients} alt="" />
                    </span>{" "}
                    <span>Patients </span> 
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items2"></ul>
                </li>
                <li className="submenu">
                  <Link to={"/appoinmentlist/" + props?.state} id="menu-item4" onClick={(e) => handleClick(e, "menu-item4", "menu-items4")}>
                    <span className="menu-side">
                      <img src={menuicon04} alt="" />
                    </span>{" "}
                    <span> Appointments </span>
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items4"></ul>
                </li>
                
                <li>
                  <Link to={"/settings/" + props?.state}>
                    <span className="menu-side">
                      <img src={menuicon16} alt="" />
                    </span>{" "}
                    <span>Settings</span>
                  </Link>
                </li>
                
              </ul>
              <div className="logout-btn">
                <Link onClick={handlelogout}>
                  <span className="menu-side">
                    <img src={logout} alt="" />
                  </span>{" "}
                  <span>Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  )
}
export default Sidebar
