import React, {useEffect, useRef, useState } from "react";
import { Link , useNavigate} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import { login02, loginicon01, loginlogo ,PhoneIcon } from "../../imagepath";

import {auth, provider ,db} from "../../../dicom/db/firebase";
import {signInWithPopup , signInWithEmailAndPassword} from "firebase/auth";

import {
  collection,
  getDocs,
  where,
  query 
} from "firebase/firestore";

const Login = () => {
  
  const history = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const email = result.user.email;

      const usersCollectionRef = collection(db, "users");
      const doc_refs = await getDocs(query(usersCollectionRef, where("email", "==", email)));

      const res = [];
      doc_refs.forEach((country) => {
        res.push({
          id: country.id,
          ...country.data(),
        });
      });

      const userRole = res[0]?.roles || "User"; // Default to "User" if no role is found

      // Save user email and role to local storage
      localStorage.setItem("email", email);
      localStorage.setItem("role", userRole);

      // Redirect based on user role
      if (userRole === "Admin") {
        history(`/Adminhome/${res[0].id}`);
      } 
      else if (userRole === "Center") {
        const centerstaffCollectionRef = collection(db, "centerstaff");
        const center = await getDocs(query(centerstaffCollectionRef, where("userId", "==", res[0].id)));
        const res2 = [];
        center.forEach((country) => {
          res2.push({
            id: country.id,
            ...country.data(),
          });
        });
        history({
          pathname: `/Centerpatient-dashboard/${res2[0].CenterId}`,
        });
      } else {
        history({
          pathname: `/user-patient-dashboard/${res[0].id}`,
        });
      }
    } catch (error) {
      console.error("Google login error:", error);
    }
  };


  // const [results, setResults] = useState([])
  const handleSignIn = async (e) =>{
    e.preventDefault();
     const handle = async () =>{
      const usersCollectionRef = collection(db, "users");
      // console.log(email)
       const doc_refs = await getDocs(query(usersCollectionRef, where("email", "==", email)))
          
          const res = []
          doc_refs.forEach(country => {
            res.push({
              id: country.id, 
              ...country.data()
            })
          })
          console.log(doc_refs);
          console.log(res , res[0].role);
          
          if(res[0].roles == "Admin"){
            history('/Admin/' + res[0].id) 
          }
          else if(res[0].roles == "Center"){
            const centerstaffCollectionRef = collection(db, "centerstaff");
            const center = await getDocs(query(centerstaffCollectionRef, where("userId", "==", res[0].id)))
            const res2 = [];
            center.forEach(country => {
            res2.push({
              id: country.id, 
              ...country.data()
            })
          })
            history({
              pathname: '/profile/' + res2[0].CenterId
            })
          }
          else if(res[0].roles == "User"){
            history({
              pathname: '/profile/' + res[0].id
            })
          }
        }
        
    signInWithEmailAndPassword(auth, email, password).then((userCredential) =>{
      console.log(userCredential);
      // console.log(results);
      handle();
        
    }).catch((error) => {
      console.log(error);
      history('/login')
    })
  }

    useEffect(()=>{
        // setGoogle(localStorage.getItem('email'))
    })

  let inputRef = useRef();
  const showIcon = () => <i className="feather feather-eye" aria-hidden="true">
    <FeatherIcon icon="eye" />
  </i>;
  const hideIcon = () => <i className="feather feather-eye-slash" aria-hidden="true">
    <FeatherIcon icon="eye-off" />
  </i>

    return (
      <>
       {/* Main Wrapper */}
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img
                    className="img-fluid"
                    src={login02}
                    alt="#"
                  />
                </div>
              </div>
            </div>
            
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Login</h2>
                      {/* Form */}
                      <form onSubmit={handleSignIn}>
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input 
                          name="email" 
                          className="form-control" 
                          type="email" 
                          value={email}
                          onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input 
                          ref={inputRef} 
                          name="password" 
                          className="form-control pass-input" 
                          type="password"
                           value={password}
                           onChange={(e) => setPassword(e.target.value)} />
                          <ReactPasswordToggleIcon
                            inputRef={inputRef}
                            showIcon={showIcon}
                            hideIcon={hideIcon}
                          />
                        </div>
                        <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {" "}
                              Remember me
                              <input type="checkbox" name="radio" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <Link to="/forgotpassword">Forgot Password?</Link>
                        </div>
                        <div className="form-group login-btn">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Need an account? <Link to="/signup">Sign Up</Link>
                        </p>
                        {/* Social Login */}
                        <div className="social-login">
                          <Link to="/phoneLogin">
                            <img src={PhoneIcon} alt="#"/>
                          </Link>
                          <Link to="#" onClick={handleGoogle}>
                            <img src={loginicon01} alt="#"/>
                          </Link>
                        </div>
                        {/* /Social Login */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
      </>
    ) 
  }

export default Login;
