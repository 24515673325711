import React, { useState } from 'react';
import { useParams , useNavigate} from 'react-router-dom';


const Admin_Dashboard = () => {
  const history = useNavigate();
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const {Admin} = useParams();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const renamedFile = renameFile(selectedFile); // Call function to rename the file
    setFile(renamedFile);
  };

  // Function to rename the file
  const renameFile = (file) => {
    const newName = Admin + ".zip";
    return new File([file], newName, { type: file.type });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://xgenclick.com/dicomupload.php', {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      setResult(data);
      
    } catch (error) {
      console.error('Error:', error);
    }
    history('/');
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Upload File</button>
      </form>
      {result && <div>Result: {result}</div>}
    </div>
  );
};

export default Admin_Dashboard;
