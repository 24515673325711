/* eslint-disable no-unused-vars */
import React from 'react'
import Header from '../components/Header';
import { useState, useEffect} from 'react';
import { Link , useParams} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';


import { db } from "../dicom/db/firebase";
import {
  collection,
  getDocs,
  where,
  query,
} from "firebase/firestore";


const Profile = () => {
  
  const {Center} = useParams();
  

const [results, setresults] = useState([]);
    const resultsCollectionRef = collection(db, "x-ray");

        const getresults = async () => {
          try {
            var fdata = []

            const data = await getDocs(query(resultsCollectionRef, where("PatientId", "==", Center)));
            const res = []
            data.forEach(country => {
              res.push({
                id: country.id, 
                ...country.data()
              })
            })

            const centerCollectionRef = collection(db, "center");
            const doc_refs = await getDocs(query(centerCollectionRef))
            const res1 = []
            doc_refs.forEach(country => {
              res1.push({
                id: country.id, 
                ...country.data()
              })
            })
            
            const usersCollectionRef = collection(db, "users");
            const doc_refs1 = await getDocs(query(usersCollectionRef))              
            const res2 = []
            doc_refs1.forEach(country => {
              res2.push({
                id: country.id, 
                ...country.data()
              })
            })
            var g =0;
            for (let i = 0; i < res.length; i++) {
              for (let a = 0; a < res1.length; a++) {
                // if(res[i].CenterId == res1[a].id){
                  for (let b = 0; b < res2.length; b++) {
                    if(res[i].PatientId == res2[b].id){
                      fdata.push({
                      id: g++,
                      Name: res2[b].name,
                      type: res[i].type,
                      Report: res[i].Report,
                      Dicom: res[i].Dicom,
                      Date: res[i].Date,
                      Employee: res1[a].Name,
                    })
                     setresults(fdata);
                    }
                  // }
                }
              }
            }
            console.log(fdata)
            // setresults(data.docs.map((doc) => ({ 
            //   ...doc.data(),
            //    id: doc.id,
            // })));

          } catch (err) {
            console.error(err);
          }
        };

        useEffect(() => {
          getresults();
        }, []);


    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = (newSelectedRowKeys) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };
      const onChange = (date, dateString) => {
        // console.log(date, dateString);
      };

      const Table = results.map((record) =>
          <div className="col-sm-6 col-md-6 col-xl-6" key={record.id}>
            <div className="blog grid-blog">
              <div className="blog-content">
                <div className="blog-grp-blk">
                  <div className="blog-img-blk">
                    
                    <div className="content-blk-blog ms-2">
                      <h4>
                          <Link to="profile.html">{record.Name}</Link>
                      </h4>
                      <h5>{record.type}</h5>
                    </div>
                  </div>
                  <span>
                    <i className="feather-calendar me-1" />
                      {record.Date}
                  </span>
                </div>
                <h3 className="blog-title">
                  
                  {record.Report != "No File Chosen" && (
                    record.Dicom != "No File Chosen" && (
                      <Link to="#" >
                        The Results Had Finished
                      </Link>
                    )
                  )}

                  {record.Report == "No File Chosen" && (
                    record.Dicom != "No File Chosen" && (
                      <Link to="#" >
                        The Results are in progress
                      </Link>
                    )
                  )}

                  {record.Dicom == "No File Chosen" && (
                    record.Report != "No File Chosen" && (
                      <Link to="#" >
                        The Results are in progress
                      </Link>
                    )
                  )}

                  {record.Dicom == "No File Chosen" && (
                    record.Report == "No File Chosen" && (
                      <Link to="#" >
                        The Results are in progress
                      </Link>
                    )
                  )}
                </h3>
                <br />
                <p>
                  {record.Report != "No File Chosen" && (
                    <Link to="#" >
                      <i className="fa-regular fa-file-lines"></i> Download Report
                    </Link>
                  )}
                  {record.Report === "No File Chosen" && (
                    <Link to="#" >
                      <i className="fa-solid fa-exclamation"></i> Report in Progress
                    </Link>
                  )}
                </p>
                <p>
                  {record.Dicom != "No File Chosen" && (
                    <Link to="#" >
                      <i className="fa-regular fa-file-image"></i> View Dicom File
                    </Link>
                  )}
                  {record.Dicom === "No File Chosen" && (
                    <Link to="#" >
                      <i className="fa-solid fa-exclamation"></i> Dicom in Progress
                    </Link>
                  )}
                </p>
              </div>
            </div>
          </div>
      );

    const columns = [
         {
            title: "Name",
            dataIndex: "Name",
            render: (text, record) => (
                <>
                    <h2 className="profile-image">
                        <Link to="#">{record.Name}</Link>
                    </h2>

                </>
            ),
            sorter: (a, b) => a.Name.length - b.Name.length
        },
        {
            title:"X-Ray Image",
            dataIndex: "type",
                sorter: (a, b) => a.Xrayimage.length - b.Xrayimage.length
        },
        {
            title:"Report",
            dataIndex: "Report",
            render: (text, record) => (
            <div>
                {text === "Yes" && (
                <Link to="#" >
                      <i className="fa-regular fa-file-lines"></i> Report</Link>
                )}
                {text === "No" && (
                <Link to="#" >
                      <i className="fa-solid fa-exclamation"></i> No File</Link>
                )}
            </div>
          ),
        },
        {
            title:"Dicom",
            dataIndex: "Dicom",
            render: (text, record) => (
            <div>
                {text === "Yes" && (
                <Link to="#" >
                      <i className="fa-regular fa-file-image"></i> View</Link>
                )}
                {text === "No" && (
                <Link to="#" >
                      <i className="fa-solid fa-exclamation"></i> No File</Link>
                )}
            </div>
          ),
        },
         {
            title:"Date",
            dataIndex: "Date",
                sorter: (a, b) => a.JoiningDate.length - b.JoiningDate.length
        },
        {
          title: "",
          dataIndex: "FIELD8",
          render: (text, record) => (
            <>
              <div className="text-end">
                <div className="dropdown dropdown-action">
                  <Link
                    to="#"
                    className="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fas fa-ellipsis-v" />
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link className="dropdown-item" to="/editdoctor">
                      <i className="far fa-edit me-2" />
                      Edit
                    </Link>
                    <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_patient">
                       <i className="fa fa-trash-alt m-r-5"></i> Delete</Link>
                  </div>
                </div>
              </div>
            </>
          ),
        },
    ]


  return (
    <>
    <Header state={Center}/>
    <>
  <div className="page-wrapper">
    <div className="content">
      {/* Page Header */}
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="#">Results </Link>
            </li>
              <li className="breadcrumb-item">
                <i className="feather-chevron-right">
                  <FeatherIcon icon="chevron-right" />
                  </i>
              </li>
              <li className="breadcrumb-item active">Results List</li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Page Header */}
      <div className="row">
        <div className="row">
          {Table}
          <div className="col-sm-6 col-md-6 col-xl-6">
            <div className="blog grid-blog">
              <div className="blog-content">
                <div className="blog-grp-blk">
                
                </div>
                <h3 className="blog-title">
                    <Link to="#">
                        The Results Had Finished
                    </Link>
                </h3>
                <br />
                <p>
                    <Link to={"/dicom/" + Center} ><i className="fa-regular fa-file-image"></i> View Dicom File</Link>
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-6 col-md-6 col-xl-6">
              <div className="blog grid-blog">
                  <div className="blog-content">
                      <div className="blog-grp-blk">
                          <div className="blog-img-blk">
                              <Link to="profile.html">
                                  <img
                                      className="img-fluid"
                                      src={blogimg4}
                                    alt="#"
                                  />
                              </Link>
                              <div className="content-blk-blog ms-2">
                                  <h4>
                                      <Link to="profile.html">Center Name</Link>
                                  </h4>
                                  <h5>M.B.B.S, Neurologist</h5>
                              </div>
                          </div>
                          <span>
                              <i className="feather-calendar me-1" />
                              27 Sep 2023
                          </span>
                      </div>
                      <h3 className="blog-title">
                          <Link to="#">
                              The Results Had Finished
                          </Link>
                      </h3>
                      <br />
                      <p>
                          <Link to="#" ><i className="fa-regular fa-file-lines"></i> View Report</Link>
                      </p>
                      <p>
                          <Link to="#" ><i className="fa-regular fa-file-image"></i> View Dicom File</Link>
                      </p>
                  </div>
              </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  <div id="delete_patient" className="modal fade delete-modal" role="dialog">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-body text-center">
          {/* <img src={imagesend} alt="#" width={50} height={46} /> */}
          <h3>Are you sure want to delete this ?</h3>
          <div className="m-t-20">
            {" "}
            <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
              Close
            </Link>
            <button type="submit" className="btn btn-danger">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="delete_patient" className="modal fade delete-modal" role="dialog">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-body text-center">
          {/* <img src={imagesend} alt="#" width={50} height={46} /> */}
          <h3>Are you sure want to delete this ?</h3>
          <div className="m-t-20">
            {" "}
            <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
              Close
            </Link>
            <button type="submit" className="btn btn-danger">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</>


    <>

</>

</>

  )
}

export default Profile;
