import React from "react";
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route } from "react-router-dom";
import DicomMain from './dicom/dicom'
import Login from "./components/pages/login";
import Admin_Dashboard from "./components/Dashboard/Admin_Dashboard/Admin_Dashboard";
import Admin_Dashboard1 from "./components/Dashboard/Admin_Dashboard/Admin_Dashboard1";
import Profile from "./dicom/profile"
//Accounts
const Approuter = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile/:Center" element={<Profile />} />
          <Route path="/dicom/:Center" element={<DicomMain />} />
          <Route path="/Admin/:Admin" element={<Admin_Dashboard1 />} />
          <Route path="/Adminhome/:Admin" element={<Admin_Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Approuter;
