import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {getFirestore} from "@firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDivVZ2MXml9HdOVMYOn-je5bxljKmt59g",
  authDomain: "karam-project-be902.firebaseapp.com",
  projectId: "karam-project-be902",
  storageBucket: "karam-project-be902.appspot.com",
  messagingSenderId: "572444519306",
  appId: "1:572444519306:web:a526264e67836cdd24f673"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);
const db = getFirestore(app);
export {auth, provider, db, storage};